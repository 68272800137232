
import Vue from "vue";
import { getPathAPI } from "@/helpers";
import { atlasfileMapActions } from "@/store/modules/atlasfile";

export default Vue.extend({
  name: "DisplayAttachment",
  props: {
    attachments: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      accessToken: localStorage.getItem("access"),
      attachmentData: null,
      modalTitle: "",
      modalDescription: ""
    };
  },
  methods: {
    ...atlasfileMapActions(["updateAtlasfile", "deleteAtlasFile"]),
    url() {
      return getPathAPI();
    },
    async getAttachmentPayload(payload: any) {
      this.modalTitle = this.restoreOrDeleteAttachmentText(payload.deleted);
      this.modalDescription = `Click Okay to ${this.modalTitle} Attachment`;
      this.$modal.show("showModal");
      this.attachmentData = payload;
    },
    restoreOrDeleteAttachmentText(isDeleted: boolean): string {
      return isDeleted ? "Restore" : "Delete";
    },
    restoreOrDeleteTextColor(isDeleted: boolean): string {
      return isDeleted ? "text-green-600" : "text-red-500";
    },
    async restoreAttachment(attachment: any) {
      try {
        const response = await this.updateAtlasfile({
          id: attachment._id,
          update: { deleted: !attachment.deleted }
        });
        if (response) {
          this.$emit("deleteRestoreAttachment", response);
        }

        this.$appNotifySuccess(`File ${(this, this.modalTitle)}d`);
        this.$modal.hide("showModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    },
    async deleteAttachment(id: string) {
      try {
        const response = await this.deleteAtlasFile(id);

        this.$emit("deleteRestoreAttachment", response);
        this.$appNotifySuccess(`File ${(this, this.modalTitle)}d`);
        this.$modal.hide("showModal");
      } catch (error) {
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    handleDeleteRestoreAttachment(): any {
      const attachment: any = this.attachmentData;
      const restoreOrDelete = attachment.deleted;
      switch (restoreOrDelete) {
        case true:
          this.restoreAttachment(attachment);
          break;
        case false:
          this.deleteAttachment(attachment.id);
          break;
        default:
          break;
      }
      return null;
    }
  }
});
