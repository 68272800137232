var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.attachments && _vm.attachments.length)?_c('div',{staticClass:"sm:col-span-2 mt-10"},[_c('dt',{staticClass:"text-sm font-medium text-gray-500"},[_vm._v(" Attachments ")]),_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_c('ul',{staticClass:"border border-gray-200 rounded-md divide-y divide-gray-200"},_vm._l((_vm.attachments),function(attachment,index){return _c('li',{key:index,staticClass:"pl-3 pr-4 py-3 flex items-center justify-between text-sm"},[_c('div',{staticClass:"w-0 flex-1 flex items-center"},[_c('i',{staticClass:"el-icon-paperclip text-gray-400"}),_c('span',{staticClass:"ml-2 flex-1 w-0 truncate"},[_vm._v(" "+_vm._s(attachment.name)+" ")])]),_c('div',{staticClass:"ml-4 flex-shrink-0"},[_c('button',{class:`fel-button el-button--text el-button--medium ${_vm.restoreOrDeleteTextColor(
                  attachment.deleted
                )}`,on:{"click":function($event){return _vm.getAttachmentPayload(attachment)}}},[_vm._v(" "+_vm._s(_vm.restoreOrDeleteAttachmentText(attachment.deleted))+" ")])]),_c('div',{staticClass:"ml-4 flex-shrink-0"},[_c('a',{staticClass:"font-medium text-indigo-600 hover:text-indigo-500",attrs:{"download":"","href":`${_vm.url()}/file/view/${
                  attachment._id
                }?accessToken=${_vm.accessToken}`,"target":"_blank"}},[_vm._v(" Download ")])])])}),0)]),_c('div',[_c('ModalMessage',{attrs:{"name":"showModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":`${_vm.modalTitle} Attachment`,"description":_vm.modalDescription,"showClose":true,"buttons":[
          {
            text: `Okay`,
            classList: [''],
            disabled: true,
            click: () => _vm.handleDeleteRestoreAttachment
          },
          {
            text: `Cancel`,
            classList: ['bg-red-700'],
            click: () => _vm.$modal.hide('showModal')
          }
        ]}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }